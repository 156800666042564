/* global fsLazy */

(function () {
  function _initArticlesCarousels() {
    if (0 === $(".articles_carousel").length) {
      return;
    }

    $(".articles_carousel").each(function () {
      var url = $(this).attr("data-url"),
              sort = $(this).attr("data-sort"),
              button = $(this).attr("data-button"),
              carousel = $(this),
              carouselGoods = carousel.find('.mainCarousel');

      if ("undefined" === typeof url || "" === url || "" === button) {
        return;
      }

      if ('/' === url) {
        url = '/bukety/';
      }

      $.ajax({
        type: 'get',
        url: '/usr-ajax-carousel/',
        cache: false,
        async: true,
        data: 'uri=' + encodeURIComponent(url) + '&button=' + encodeURIComponent(button) + (sort ? '&sort=' + sort : '') + ($(this).data("not-quick-view") ? '&not-quick-view=1' : '') + '&inactive=1',
        dataType: 'json',
        beforeSend: function () {}
      }).done(function (response) {
        carouselGoods.html(response.html);
        carouselGoods.owlCarousel({
          responsiveBaseWidth: 'main',
          mouseDraggable: false,
          rewindNav: false,
          pagination: true,
          navigation: true,
          navigationText: ["", ""],
          slideSpeed: 500,
          paginationSpeed: 500,
          scrollPerPage: true,
          lazyLoad: false,
          itemsCustom: [[0, 2], [421, 4], [990, 5]]
        });
        fsLazy.update();
      });
    });
  }

  function _initSmlLong() {
    if ($(".fs-owl.sml-long").length > 0) {

      var param = [[0, 2], [421, 5], [900, 6], [1200, 7]];

      if ($("body.wide").length > 0) {
        param = [[0, 7], [1250, 9]];
      }

      if ($(".fs-owl.sml-long").hasClass('photogallery')) {
        $(".fs-owl.sml-long.photogallery").owlCarousel({
          itemsCustom: param,
          navigation: true,
          navigationText: ["", ""],
          afterMove: function () {
            var items = this.$userItems, visible_items = this.visibleItems;
            for (i in visible_items) {
              var cur = visible_items[i];
              var cur_img = $(items[cur]).find('img');
              if (cur != 'undefined' && cur_img != 'undefined' && (cur_img.attr('src') != cur_img.attr('data-original')))
                cur_img.attr('src', cur_img.attr('data-original'));
            }
          }
        });
      } else {
        $(".fs-owl.sml-long").owlCarousel({
          itemsCustom: param,
          navigation: true,
          navigationText: ["", ""],
        }).parent("div").slideDown();
      }
    }
    
    if ($(".fs-owl-static").length > 0) {
        $(".fs-owl-static").owlCarousel({
          itemsCustom: [[0, 2], [421, 4], [990, 5]],
          navigation: true,
          navigationText: ["", ""],
        });
    }

    if ($(".fs-owl-static-horizontal").length > 0) {
        $(".fs-owl-static-horizontal").owlCarousel({
          itemsCustom: [[0, 1], [421, 3], [990, 4]],
          navigation: true,
          navigationText: ["", ""],
        });
    }
  }
  function _initAccordion() {
    if (0 === $('.fs-accordion').length)
      return;
    var allDd = $('.fs-accordion dd');
    var allDt = $('.fs-accordion dt');

    $('.fs-accordion dt').click(function () {
      $this = $(this);
      $target = $this.next();
      if (!$this.hasClass('panel-show')) {
        allDd.slideUp();
        $target.slideDown();
        allDt.removeClass('panel-show');
        $this.addClass('panel-show');
      }
      return false;
    });
  }

  function _initYaMap() {
    if ($("#yamap").length > 0) {//@date 20140418
      $.getScript('https://api-maps.yandex.ru/2.1/?load=package.standard&lang=ru_RU', function () {
        ymaps.ready(init);
      });

      function init() {
        var myMap = new ymaps.Map("yamap", {
          center: [55.77488308, 37.70473001],
          zoom: 15
        }),
                myPlacemark = new ymaps.Placemark([55.77488308, 37.70473001], {
                  // Чтобы балун и хинт открывались на метке, необходимо задать ей определенные свойства.
                  balloonContentHeader: "Flower-shop.ru",
                  balloonContentBody: "<p style='white-space:nowrap;margin:1px 0;'>Время работы с 9:00 до 21:00 (MSK)</p>",
                  balloonContentFooter: "+7 (495) 626-4414",
                  hintContent: ""
                });

        myMap.geoObjects.add(myPlacemark);
        myMap.controls.add('zoomControl', {top: 5, left: 5});
      }
    }
  }

  function _initMainCarousel() {
    $(".mainCarousel").each(function () {
      if ($(this).html() != '') {
        $(this).owlCarousel({
          responsiveBaseWidth: '.mainCarousel',
          mouseDraggable: false,
          rewindNav: false,
          pagination: true,
          navigation: true,
          navigationText: ["", ""],
          slideSpeed: 500,
          paginationSpeed: 500,
          scrollPerPage: true,
          lazyLoad: true,
          itemsCustom: [[0, 2], [421, 4], [990, 5]]
        });
      }
    });
  }

  _initMainCarousel();
  _initArticlesCarousels();
  _initSmlLong();
  _initYaMap();
  _initAccordion();
})();
